import React from 'react';

import { BroadbandBannerContent, SPLITIO_KEY, useJSONConfig, useSplitIO } from '@sky-tv-group/shared';

import { configService } from '../../services';

interface BroadbandBannerProps {
  priceOverride?: string;
}

function BroadbandBanner({ priceOverride }: BroadbandBannerProps) {
  const config = useJSONConfig(configService);
  const [BBCreditOfferFlag] = useSplitIO(SPLITIO_KEY.SKYWEB_BB_CREDIT_OFFER);

  const content: BroadbandBannerContent = config?.broadband?.acquisition?.mainBanner;

  if (!content) return null;
  if (priceOverride) content.price = priceOverride;

  return (
    <div>
      <div
        className="flex bg-no-repeat bg-cover md:hidden lg:hidden"
        style={{
          backgroundImage: 'url(' + 'https://static.sky.co.nz/sky/broadband/img/Tab_BB_SPA_FebOffer_mobile.png' + ')',
          height: '325px',
          backgroundSize: '100% 100%',
        }}>
        <div className="flex flex-col justify-end	items-center">
          <div className="flex-wrap z-10 relative ">
            <div className="sky-h2-black sky-text-white lg:hidden block relative text-center">
              {BBCreditOfferFlag ? (
                <p>
                  <span style={{ fontSize: 32, lineHeight: '40px', color: '#F2A040' }}>6 months half price </span>
                  <p style={{ color: '#FFF', fontSize: 20, lineHeight: '24px' }}>
                  when you join and bundle Sky TV and Sky Broadband. Plus, new Sky Box or Sky Pod on us!
                  </p>
                </p>
              ) : (
                content.title
              )}
            </div>
            {BBCreditOfferFlag ? (
              <p className="sky-h6-reg sky-text-white text-center my-2" style={{ fontSize: 14, lineHeight: '18px' }}>
                Sky Starter on a 12 month contract. Offer ends 31 July 2024.{' '}
                <a
                  className="md:inline underline"
                  href="https://get.sky.co.nz/offer-terms"
                  style={{ lineHeight: '18px', fontSize: 14 }}>
                  Offer T&Cs
                </a>
                <span className="banner_font_reg">and exit fees apply.</span>
              </p>
            ) : (
              <>
                <p className="sky-h7-reg md:sky-h6-reg sky-text-white mt-5" style={{ lineHeight: '30px' }}>
                  {content.description}
                </p>
                <div className="mt-2">
                  <span className="sky-h2-bold text-bborange">{content.price}</span>
                  <span className="sky-h2-bold sky-text-white">{content.other}</span>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      <div
        className="hidden md:flex bg-no-repeat bg-cover lg:hidden"
        style={{
          backgroundImage: 'url(' + 'https://static.sky.co.nz/sky/broadband/img/Tab_BB_SPA_FebOffer_mobile.png' + ')',
          height: '600px',
        }}>
        <div className="flex flex-col justify-end	items-center">
          <div className=" z-10 relative mb-3">
            <div className=" sky-text-white lg:hidden block relative text-center my-3">
              {BBCreditOfferFlag ? (
                <p>
                  <span className='sky-h2-black' style={{ color: '#F2A040',fontSize: '56px',lineHeight: '60px' }}>6 months half price </span>
                  <p className='sky-h2-bold' style={{ color: '#FFF',fontSize: '28px',lineHeight: '32px' }}>when you join and bundle Sky TV and Sky Broadband. Plus, new Sky Box or Sky Pod on us!</p>
                </p>
              ) : (
                content.title
              )}
            </div>
            {BBCreditOfferFlag ? (
              <p className="md:sky-h5-reg sky-text-white text-center ml-3">
                Sky Starter on a 12 month contract. Offer ends 31 July 2024.{' '}
                <a
                  className="md:inline underline"
                  href="https://get.sky.co.nz/offer-terms"
                  style={{ lineHeight: '20px', fontSize: 24 }}>
                  Offer T&Cs
                </a>
                <span className="banner_font_reg"> and exit fees apply.</span>
              </p>
            ) : (
              <>
                <p className="sky-h7-reg md:sky-h6-reg sky-text-white mt-5" style={{ lineHeight: '30px' }}>
                  {content.description}
                </p>
                <div className="mt-2">
                  <span className="sky-h2-bold text-bborange">{content.price}</span>
                  <span className="sky-h2-bold sky-text-white">{content.other}</span>
                </div>
              </>
            )}
          </div>
        </div>
      </div>

      <div
        className="hidden lg:flex relative w-100 h-120 d-flex align-items-center bg-center bg-cover pt-72 pb-12 md:pt-32 md:pb-10 text-center lg:text-left px-6"
        style={{
          backgroundImage:
            'url(' + 'https://static.sky.co.nz/sky/broadband/img/Desktop_BB_SPA_FebOffer_banner.jpg' + ')',
          height: '340px',
        }}>
        <div className="container mx-auto " style={{ marginLeft: '40px' }}>
          <div className="z-10 relative" style={{ width: '50rem' }}>
            <div
              className="sky-text-white hidden lg:block lg:mt-8 lg:mb-3  "
              style={{  width: 725  }}>
              {BBCreditOfferFlag ? (
                <p>
                  <span className='md:sky-h2-black mb-1' style={{ color: '#F2A040',fontSize: '56px',lineHeight: '46px' }}>6 months half price</span>
                  <p className='md:sky-h2-bold' style={{ color: '#FFF',fontSize: '28px',lineHeight: '32px' }}>when you join and bundle Sky TV and Sky Broadband. Plus, new Sky Box or Sky Pod on us!</p>
                </p>
              ) : (
                content.title
              )}
            </div>

            {BBCreditOfferFlag ? (
              <div>
                <p className="sky-h3-reg sky-text-white" style={{ lineHeight: '28px', fontSize: '24px' }}>
                Sky Starter on a 12 month contract. Offer ends 31 October 2024.{' '}
                  <a
                    className="hidden md:inline underline sky-h3-reg sky-text-white"
                    style={{ fontSize: 24 }}
                    href="https://www.sky.co.nz/offer-terms">
                    Offer T&Cs
                  </a>
                  &nbsp;
                  <span className="banner_font_reg">and exit fees apply.</span>
                </p>
              </div>
            ) : (
              <>
                <p className="sky-h6-reg sky-text-white mt-5" style={{ lineHeight: '30px' }}>
                  {content.description}
                </p>
                <div className="mt-2">
                  <span className="sky-h2-bold text-bborange">{content.price}</span>
                  <span className="sky-h2-bold sky-text-white">{content.other}</span>
                </div>
              </>
            )}
          </div>
        </div>
      </div>

      <div
        className="absolute w-full h-1/2 bottom-0"
        style={{ background: `linear-gradient(to top, #00013A 25%, rgba(2, 0, 36, 0) 85% )` }}></div>
    </div>
  );
}

export { BroadbandBanner };
